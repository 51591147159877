<template>
  <v-container
  id="manual-positions"
  fluid
  tag="section">
    <v-row>
      <v-col class="12">
        <v-row class="ml-5 mr-5 mt-5">
          <v-col cols="12">
            <p><b>How to upload Manual positions file</b></p>
            <p><b> </b></p>
            <ul>
              <li>Download and edit the csv template file. The name of uploaded file must start with
              'Manual_Detailed_positions'.</li>
              <li>The accepted file format is .csv.</li>
              <li>First row must contain the date in the format dd/mm/yyyy. For example 08/23/2023.
              <li>Symbol values should not exceed 12 characters. Only the first 12 characters will be saved if any value
              exceeds the limit.
              <li>The accepted time window to upload manual positions for the current day is from 12:00AM to 10:00AM the
              next day.</li>
            </ul>
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col cols="8">
            <v-file-input
            v-model="file"
            accept=".csv"
            label="Click to attach file *"
            :disabled="isLoading"
            counter
            show-size
            clearable
            class="pt-0"
            />
          </v-col>
          <v-col
          cols="2"
          class="text-right">
            <v-btn
            color="primary"
            :disabled="isLoading || !file"
            @click="uploadManualPositionsFile(file)">
              Upload Manual Positions
            </v-btn>
            <v-btn
            color="secondary"
            :disabled="isLoading"
            @click="downloadManualPositionsFile()">
              Download template
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <div class="text-center">
        <v-dialog
          v-model="dialog.show"
          width="500"
        >
          <v-card>
            <v-card-title class="headline lighten-2">
              <span
                :class="`${dialog.error ? 'red--text' : 'blue--text'} text-uppercase`"
                style="letter-spacing: 0.3rem"
              > {{ dialog.title }} </span>
            </v-card-title>

            <v-card-text
              align="center"
            >
              {{ dialog.message }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog.show = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import ManualPositionsService from './ManualPositionsService'

export default {
  name: 'SystemSettings',
  data: () => ({
    tab: 0,
    file: null,
    isLoading: false,
    dialog: {
      show: false,
      title: '',
      message: ''
    }
  }),
  methods: {
    async saveManualPositionsFile (file) {
      this.isLoading = true
      const response = await ManualPositionsService.uploadManualPositionsFile(file)
      if (response.status === 200) {
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = 'Successfully uploaded Manual positions file!'
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = response.error.message
      }
      this.file = null
      this.isLoading = false
    },
    async downloadManualPositionsFile () {
      this.isLoading = true
      const response = await ManualPositionsService.downloadManualPositionsFile()
      if (response.status === 200) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Manual_Detailed_positions.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot download Manual positions file!'
      }
      this.isLoading = false
    },
    async uploadManualPositionsFile (file) {
      await this.saveManualPositionsFile(file)
    }
  }
}
</script>
<style scoped>
.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}
</style>
