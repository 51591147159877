import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'

const APIPOSITIONS = '/api/v1/system-settings/manual-positions'

// upload manual positions file
const uploadManualPositionsFile = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return apiResultWrapper.apiResult(await http.post(`${APIPOSITIONS}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }))
}

const downloadManualPositionsFile = async () => {
  return await http.get(`${APIPOSITIONS}/download`, { responseType: 'blob' })
}

export default {
  uploadManualPositionsFile,
  downloadManualPositionsFile
}
